import { apiFetch } from "./fetch";

export class ImageHelper {
  api: string;
  nextUrl: string | undefined;
  pathPattern: string;

  constructor() {
    this.pathPattern = `BASE_URL/images/`;
    this.api = "/api/images";
    this.nextUrl = this.api;
  }

  // clearCache = async (): Promise<void> => {
  //   return await apiFetch("/api/cache", { method: "DELETE" });
  // };

  hasMoreImages() {
    return undefined !== this.nextUrl;
  }

  fetchImage = async (id: number): Promise<Image | undefined> => {
    const result = await apiFetch<Image>(`${this.api}/${id.toString()}`);
    return result;
  };

  fetchAll = async (): Promise<Image[]> => {
    const result = await apiFetch<ImagesResponse>(
      `${this.api}?limit=1000&visible=0`
    );
    return result._embedded?.items || [];
  };

  fetchNextImages = async (): Promise<Image[] | undefined> => {
    if (!this.nextUrl) {
      return undefined;
    }

    const result = await apiFetch<ImagesResponse>(this.nextUrl);
    this.nextUrl = result._links.next?.href;
    return result._embedded?.items;
  };

  path(image: Image, width?: ImageSize) {
    if (typeof width === "undefined") {
      width = image.width as ImageSize;
    }
    return `${this.pathPattern}${image.name}/${
      image.name
    }_${width.toString()}.jpg`;
  }

  srcSet(
    image: Image,
    sizes?: ImageSize[],
    relative: boolean = false
  ): string[] {
    if (typeof sizes === "undefined") {
      sizes = [200, 333, 414, 640, 700, 1400];
    }
    return sizes.map((w) => {
      const width = relative ? ` ${w / 700}x` : ` ${w}w`;
      return this.path(image, w) + width;
    });
  }

  deleteImage = async (image: Image): Promise<boolean> => {
    try {
      await apiFetch(`${this.api}/${image.id}`, { method: "DELETE" });
      // await this.clearCache();
    } catch (e) {
      return false;
    }
    return true;
  };

  postImage = async (data: FormData): Promise<Image> => {
    const newImage = await apiFetch<Image>(this.api, {
      method: "POST",
      body: data,
    });
    // await this.clearCache();
    return newImage;
  };

  patchImage = async (image: Image, data: FormData): Promise<Image> => {
    const newImage = await apiFetch<Image>(`${this.api}/${image.id}`, {
      method: "PATCH",
      body: data,
    });
    // await this.clearCache();
    return newImage;
  };
}

const imageHelper = new ImageHelper();
export const fetchNextImages = async () => imageHelper.fetchNextImages();
export const fetchImage = async (id: number) => imageHelper.fetchImage(id);
export const fetchAll = async () => imageHelper.fetchAll();
export const hasMoreImages = () => imageHelper.hasMoreImages();
export const imagePath = (image: Image, width?: ImageSize) =>
  imageHelper.path(image, width);
export const sourceSet = (image: Image, sizes?: ImageSize[]) =>
  imageHelper.srcSet(image, sizes);
export const relativeSourceSet = (image: Image, sizes?: ImageSize[]) =>
  imageHelper.srcSet(image, sizes, true);
export const deleteImage = async (image: Image) =>
  imageHelper.deleteImage(image);
export const patchImage = async (image: Image, data: FormData) =>
  imageHelper.patchImage(image, data);
export const postImage = async (data: FormData) => imageHelper.postImage(data);
