// represents initial state
const state: State = {
  images: [],
  labels: null,
  label: "all",
  previousContent: null,
  app: null,
  pending: 0,
};

export const getState = <T extends keyof State>(key: T): State[T] => {
  if (Reflect.has(state, key)) {
    return state[key];
  }
  throw new Error(`state/get, unkown property ${key} in state.`);
};

export const setState = <T extends keyof State>(
  key: T,
  value: typeof state[T]
): void => {
  if (state.hasOwnProperty(key)) {
    let oldValue: any;
    if (observers[key]) {
      oldValue = getState(key);
    }

    Reflect.set(state, key, value);

    if (observers[key]) {
      observers[key].forEach((observer) =>
        observer.call(undefined, value, oldValue)
      );
    }
    return;
  }
  throw new Error(`state/set, unkown property ${key} in state.`);
};

type Observer<K> = <T extends keyof State>(newValue: K, oldValue: K) => void;

type Observers = {
  [key: string]: Observer<any>[];
};

const observers: Observers = {};
export const observe = <T extends keyof State>(
  key: T,
  observer: Observer<typeof state[T]>
): void => {
  if (typeof observers[key] === "undefined") {
    observers[key] = [];
  }
  observers[key].push(observer);
};
