import { onNavigate, applyRoute, onHistory } from "helper/router";
import { setState, observe } from "store";
import style from "./styles/app.css";

(async () => {
  const app = document.querySelector<HTMLDivElement>("main");

  if (!app) {
    throw new Error("no main container");
  }

  let prefersLightTheme = "true" === window.localStorage.getItem("theme");
  const browserPrefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  if (browserPrefersDark && !prefersLightTheme) {
    document.body.classList.toggle(style.hasDarkmode, true);
  }

  document.addEventListener("keyup", (e) => {
    if (e.altKey && e.which === 84) {
      prefersLightTheme = !prefersLightTheme;
      window.localStorage.setItem("theme", JSON.stringify(prefersLightTheme));
      document.body.classList.toggle(style.forceLightTheme, prefersLightTheme);
      document.body.classList.toggle(style.hasDarkmode, !prefersLightTheme);
    }
  });
  const loadingDiv = document.createElement("div");
  loadingDiv.classList.add(style.loading);
  app.parentNode?.appendChild(loadingDiv);

  observe("pending", (n) => {
    loadingDiv.classList.toggle(style.hidden, n === 0);
    loadingDiv.style.width = 100 / n + "%";
  });

  observe("pending", (pending) => {
    console.log({ pending });
  });

  setState("app", app);
  window.addEventListener("routeChange", onNavigate as EventListener, false);
  window.addEventListener("historyChange", onHistory as EventListener, false);
  window.addEventListener("popstate", (event) => {
    applyRoute(event.state?.pathname || "/");
  });
  applyRoute(window.location.pathname + window.location.search);
})();
