import homeRoute from "pages/home";
import imageRoute from "pages/image";
import errorRoute from "pages/error";
import { listenClicks } from "helper/dom";
import { getState, setState } from "store";

export const applyRoute = async (pathname: string) => {
  console.log({ pathname });
  const previousContent = getState("previousContent");
  const app = getState("app");

  if (!app) {
    return;
  }

  const routes: Routes = {
    "/": homeRoute,
    "/image/(?<imageId>\\d+)": imageRoute,
    error: errorRoute,
  };

  const routePatterns = Object.keys(routes);
  let [path, query] = pathname.split("?");
  const urlParams = new URLSearchParams(query);
  let route: string | undefined,
    matches: RegExpMatchArray | null = null;
  // TODO caching of the routes detected
  for (let index = 0; index < routePatterns.length; index++) {
    route = routePatterns[index];
    const processedRoute = route.replace(/\//g, "\\/").replace(/\?<\w+>/g, "");
    let regexp = new RegExp("^" + processedRoute + "$");
    matches = path.match(regexp);
    if (matches) {
      break;
    }
  }

  if (!matches) {
    route = "error";
    matches = new Array();
    matches["groups"] = {
      message: "invalid route",
    };
  } else if (route) {
    // restore groups
    const groups = /\?<(\w+)>/gi.exec(route);
    if (groups) {
      matches["groups"] = {};
      for (let index = 1; index < groups.length; index++) {
        const group = groups[index];
        matches["groups"][group] = matches[index];
      }
    }
  }

  const range = document.createRange();
  range.selectNode(app);
  if (route && matches != undefined) {
    const content = await routes[route](matches["groups"], urlParams);

    if (null === content) {
      return;
    }

    const fragment = range.createContextualFragment(content.html);
    listenClicks(fragment);

    if (previousContent && previousContent.onunmount) {
      await previousContent.onunmount.call(undefined, app);
    }
    app.innerHTML = "";
    app.appendChild(fragment);
    if (content.onmount) {
      await content.onmount.call(undefined, app);
    }
    setState("previousContent", content);
  }
};

export const onHistory = (event: CustomEvent<string>) => {
  const pathname = event.detail;
  window.history.pushState(
    { pathname },
    pathname,
    window.location.origin + pathname
  );
};

export const onNavigate = (event: CustomEvent<string>) => {
  const pathname = event.detail;
  window.history.pushState(
    { pathname },
    pathname,
    window.location.origin + pathname
  );

  applyRoute(pathname);
};
