import { apiFetch } from "./fetch";

export class LabelHelper {
  api: string;

  constructor() {
    this.api = "/api/labels";
  }

  fetchLabels = async (): Promise<Label[]> => {
    const result = await apiFetch<LabelResponse>(this.api);
    return result._embedded?.items || [];
  };
}

const labelHelper = new LabelHelper();
export const fetchLabels = async () => labelHelper.fetchLabels();
