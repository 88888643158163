import { setState, getState } from "store";

export async function apiFetch<T>(
  url: string,
  opts?: RequestInit,
  safe: boolean = true
): Promise<T> {
  try {
    setState("pending", getState("pending") + 1);

    const response = await fetch(`BASE_URL${url}`, {
      credentials: "same-origin",
      ...opts,
    });

    let data: any = {};
    if (response.status !== 204) {
      // 204 === no content to be expected
      data = (await response.json()) as T | ErrorResponse;
    }

    setState("pending", getState("pending") - 1);
    if (true === safe && data.hasOwnProperty("error")) {
      throw `${data.error.message}: ${url}`;
    }

    return data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}
