import { listenClicks } from "helper/dom";
import { getState, setState } from "store";
import {
  fetchNextImages,
  hasMoreImages,
  sourceSet,
  imagePath,
} from "helper/image";
import { fetchLabels } from "helper/labels";
import style from "../styles/home.css";

class ImageGallery {
  loading: boolean;
  gallery: HTMLDivElement | undefined;

  constructor() {
    this.loading = false;
    this.gallery = undefined;
  }

  setGallery(gallery: HTMLDivElement) {
    this.gallery = gallery;
  }

  fetchLabels = async (): Promise<Label[] | null> => {
    let labels = getState("labels");
    if (null === labels) {
      labels = await fetchLabels();
      labels.unshift({
        id: 0,
        name: "All",
        value: "all",
        links: {
          _self: "all",
        },
      });
      setState("labels", labels);
    }

    return labels;
  };

  infintescroll = async () => {
    if (this.bottomReached() && !this.loading) {
      if (!hasMoreImages()) {
        window.removeEventListener("scroll", this.infintescroll, false);
        return;
      }

      this.loading = true;
      const response = await fetchNextImages();
      if (response?.length) {
        const images = getState("images");
        for (let index = 0; index < response.length; index++) {
          const image = response[index];
          this.renderImage(image);
        }
        images.push(...response);
        setState("images", images);
      }
      this.loading = false;

      if (this.bottomReached()) {
        await this.infintescroll();
      }
    }
  };

  bottomReached() {
    // make sure everything is filtered correctly
    this.filterImages();

    const buffer = 200;
    const contentBottom =
      document.documentElement.scrollTop + window.innerHeight;
    const offset = document.documentElement.offsetHeight;

    if (contentBottom >= offset - buffer) {
      return true;
    }

    return false;
  }

  filterImages() {
    if (!this.gallery) {
      return;
    }

    const label = getState("label");
    this.gallery.querySelectorAll(`figure`).forEach((image) => {
      if (label === "all" || image.classList.contains(label)) {
        image.classList.remove(style.hidden);
      } else {
        image.classList.add(style.hidden);
      }
    });
  }

  changeLabel = async (event: Event) => {
    const target = event.currentTarget as HTMLInputElement;
    if (!target) {
      return;
    }

    const label = target.value;
    window.dispatchEvent(
      new CustomEvent("historyChange", { detail: `/?label=${label}` })
    );
    setState("label", label);
    await this.infintescroll();
  };

  renderLabels(labels: Label[]) {
    let labelHTML = "";
    for (let index = 0; index < labels.length; index++) {
      const label = labels[index];
      const currentLabel = getState("label");
      const checked = label.value === currentLabel ? "checked" : "";

      labelHTML += `
        <span>
          <input id="select-type-${label.value}" name="filter-set" value="${label.value}" type="radio" class="${style.selector}" ${checked}>
          <label for="select-type-${label.value}" class="${style.filter}">${label.name}</label>
        </span>
        `;
    }

    return labelHTML;
  }

  renderImage(image: Image) {
    if (!this.gallery) {
      return;
    }

    const range = document.createRange();
    range.selectNode(this.gallery);
    const currentLabel = getState("label");
    const hidden =
      currentLabel === "all" || -1 !== image.labels.indexOf(currentLabel)
        ? ""
        : style.hidden;

    const fragment = range.createContextualFragment(`
      <figure class="${image.labels.join(" ")} ${hidden}">
        <a href="/image/${image.id}">
          <img
            loading="lazy"
            srcset="${sourceSet(image).join(",")}"
            sizes="(max-width: 414px) 414px, (max-width: 667px) 640px, 700px"
            alt="${image.title}"
            src="${imagePath(image, 700)}"
            height="${image.height}"
            width="${image.width}">
          <figcaption>
            <span>${image.title}</span>
            <span>${image.location}</span>
            ${image.shotDate.split("-")[0]}
          </figcaption>
        </a>
      </figure>
    `);
    listenClicks(fragment);

    const img = fragment.querySelector<HTMLImageElement>("img");
    // The loadstart event is not fired on <img> elements
    // @see https://caniuse.com/#feat=mdn-api_globaleventhandlers_onloadstart
    img?.addEventListener("loadstart", (e) => {
      setState("pending", getState("pending") + 1);
      e.target &&
        e.target.addEventListener("load", () => {
          setState("pending", getState("pending") - 1);
        });
    });

    this.gallery.appendChild(fragment);
  }
}

const homeRoute: Route<any> = async (_, query) => {
  const imageGallery = new ImageGallery();
  const labels = await imageGallery.fetchLabels();
  const images = getState("images");

  const currentLabel = getState("label");
  const label = query.get("label");
  if (label && currentLabel !== label) {
    setState("label", label);
  } else if (label === null && currentLabel !== "all") {
    setState("label", "all");
  }

  let labelHTML = "";
  if (labels) {
    labelHTML = imageGallery.renderLabels(labels);
  }

  return {
    onmount: async (element: HTMLDivElement) => {
      const gallery = element.querySelector<HTMLDivElement>(
        "#" + style.gallery
      );
      if (!gallery) {
        return;
      }
      imageGallery.setGallery(gallery);

      document.querySelectorAll("input[name=filter-set]").forEach((radio) => {
        radio.addEventListener("change", imageGallery.changeLabel, false);
      });

      if (hasMoreImages()) {
        window.addEventListener("scroll", imageGallery.infintescroll, false);
      }

      // if there are already some imagaes in state, render them
      if (images.length) {
        for (let index = 0; index < images.length; index++) {
          imageGallery.renderImage(images[index]);
        }
      } else {
        await imageGallery.infintescroll();
      }
    },
    onunmount: async (element: HTMLDivElement) => {
      if (hasMoreImages()) {
        window.removeEventListener("scroll", imageGallery.infintescroll, false);
      }
    },
    html: `
      <nav id="${style.categories}" navigation="labels">
        <div id="${style.navWrapper}">
          <div id="${style.navContent}">
            ${labelHTML}
          </div>
        </div>
      </nav>

      <section id="${style.gallery}"></section>
      `,
  };
};

export default homeRoute;
