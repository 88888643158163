export const listenClicks = (fragment: DocumentFragment) => {
  const links = Array.prototype.slice.call(fragment.querySelectorAll("a"));
  const navigate = (e: MouseEvent) => {
    const target = e.currentTarget as HTMLAnchorElement;
    if (!target || !target.pathname) {
      return;
    }

    // TODO handle external links
    window.dispatchEvent(
      new CustomEvent("routeChange", { detail: target.pathname })
    );
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  for (let index = 0; index < links.length; index++) {
    const element = links[index] as HTMLAnchorElement;
    element.addEventListener("click", navigate);
  }
};
