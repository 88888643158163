import { getState } from "store";
import style from "../styles/image.css";
import { fetchImage, imagePath, relativeSourceSet } from "helper/image";

const renderNavigationLink = (
  image: Image | undefined,
  icon: typeof style["navNext"] | typeof style["navPrev"]
): string => {
  if (image) {
    return `
    <a href="/image/${image.id}" class="${icon} ${style.icon}">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M344 123q0 4-3 7L229 242l112 113q3 1 3 6 0 4-3 7l-14 14q-3 3-7 3t-7-3L180 249q-3-3-3-7 0-3 3-6l133-133q3-3 7-3t7 3l14 14q3 3 3 6z"></path>
      </svg>
    </a>`;
  }
  return `<div class="${style.icon}">&nbsp;</div>`;
};

const getImage = async (imageId: string): Promise<Image | undefined> => {
  try {
    const image = await fetchImage(parseInt(imageId, 10));
    if (image && image.id.toString() === imageId) {
      return image;
    }
  } catch (e) {}
};

let initialTouch: Touch;
const handleTouchStart = (e: TouchEvent) => {
  const t = e.touches.item(0);
  if (t) {
    initialTouch = t;
  }
};

const handleTouchMove = (e: TouchEvent) => {
  const t = e.touches.item(0);
  if (!t) {
    return;
  }

  console.log({ initialTouch: initialTouch.clientX, t: t.clientX });

  const click = new Event("click", { cancelable: true });
  if (initialTouch.clientX - t.clientX > 0) {
    // right to left: next
    console.log({ right: "left" });
    document
      .querySelector(`a.${style.icon}.${style.navNext}`)
      ?.dispatchEvent(click);
  } else {
    // left to right: prev
    console.log({ left: "right" });
    document
      .querySelector(`a.${style.icon}.${style.navPrev}`)
      ?.dispatchEvent(click);
  }
};

const imageRoute: Route<RouteImageProps> = async (props) => {
  const images = getState("images");
  const index = images.findIndex((i) => i.id.toString() === props.imageId);
  let image: Image | undefined;

  if (-1 === index) {
    // try to find the image, or return to home
    image = await getImage(props.imageId);
    if (!image) {
      window.dispatchEvent(new CustomEvent("routeChange", { detail: "/" }));
      return null;
    }
  } else {
    image = images[index];
  }

  return {
    onmount: () => {
      document.addEventListener("touchstart", handleTouchStart, false);
      document.addEventListener("touchmove", handleTouchMove, false);
    },
    onunmount: () => {
      document.removeEventListener("touchstart", handleTouchStart, false);
      document.removeEventListener("touchmove", handleTouchMove, false);
    },
    html: `
      <section id="${style.detail}" class="align">
        ${renderNavigationLink(images[index - 1], style.navPrev)}
        <figure>
          <img
            alt="${image.title}"
            src="${imagePath(image, 1400)}"
            srcset="${relativeSourceSet(image, [700, 1400]).join(",")}"
            >
        </figure>
        ${renderNavigationLink(images[index + 1], style.navNext)}
      </section>

      <a class="${style.navClose}" href="/">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M367 304q9 9 9 22t-9 22q-10 8-22 8t-22-8l-68-78-67 78q-10 8-22 8t-22-8q-8-8-8-22t8-22l71-80-71-81q-8-8-8-22t8-22q10-8 22-8t22 8l67 78 68-78q10-8 22-8t22 8q9 9 9 22t-9 22l-71 81 71 80z"></path>
        </svg>
      </a>
      `,
  } as RouteResponse;
};

export default imageRoute;
