const errorRoute: Route<RouteErrorProps> = (props) => {
  return {
    html: `
      <h1>an error occured</h1>
      <p>${props.message}</p>
      <a href="/">home</a>
    `,
  };
};

export default errorRoute;
